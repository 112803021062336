import { config } from "@grafana/runtime";
import { Alert } from "@grafana/ui";
import React from "react";

const MissingDataSourceAlert: React.FC = () => {
  // if:
  // dataSource.jsonData?.defaultDatabase >> is empty string or undefined
  // or
  // dataSource.jsonData?.authType >>> is not 'keys'
  // show the alert to fix the data source. it's not perfect because we don't check that the actual credentials are
  // present, but this should be a good enough reminder. also this is admin-facing only.

  const dataSource = config.datasources['Timestream datasource'] || {jsonData: {}}

  return (
    // @ts-ignore: yeah dude, we fucking understand it might be undefined, that's why there's a goddamned ?. operator
    dataSource.jsonData?.defaultDatabase === undefined ||
    // @ts-ignore: yeah dude, we fucking understand it might be undefined, that's why there's a goddamned ?. operator
    dataSource.jsonData?.defaultDatabase === '' ||
    dataSource.jsonData?.authType !== 'keys'
  ) ? (
    <Alert
      severity='error'
      title={`Dashboards will not work until you fix ${config.bootData.user.orgName}'s data source`}
      buttonContent={<span>Fix now</span>}
      onRemove={() => location.href = `${location.origin}/connections/datasources/edit/${dataSource.uid}`}
    >
      All of the following are required: access key ID, secret access key, default database.
    </Alert>
  ) : (
    <></>
  )
}

export default MissingDataSourceAlert;
