import { PluginPage, config, getAppEvents, getBackendSrv } from '@grafana/runtime';
import { Button, DatePickerWithInput, FilterInput, InlineField, Input, InteractiveTable, LinkButton, Modal, RadioButtonGroup, Stack, useStyles2 } from '@grafana/ui';
import React, { useState } from 'react';
import { useAsync } from "react-use";
import siteTemplate from 'dashboards/site.json'
import { BASE_URLS } from 'shared/constants';
import { Site } from 'shared/types';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/css';
import { AppEvents, GrafanaTheme2, SelectableValue } from '@grafana/data';
import MapComponent from 'fgs-admin/components/BBoxSelectionMap';
import MissingDataSourceAlert from 'fgs-admin/components/MissingDataSourceAlert';

const backendSrv = getBackendSrv();
const appEvents = getAppEvents();

export function Sites() {
  const s = useStyles2(getStyles);
  const navigate = useNavigate()

  const pageNav = {
    text: `Sites - ${config.bootData.user.orgName}`,
    subTitle: 'Manage sites and sensor locations.'
  }

  // manage the sites table
  const [searchQuery, setSearchQuery] = useState('');
  const [isActiveFilter, setIsActiveFilter] = useState(true);
  const activeOptions = [{label: 'Active', value: true}, {label: 'Expired', value: false}];

  const { loading, value: allSites } = useAsync(async () => {
    const response = await backendSrv.get(`${BASE_URLS.API}/sites`);
    return response?.data?.sites || []
  })

  const cols = React.useMemo(() => {
    return [
      {id: 'name', header: 'Name', sortType: 'string', cell: ({row: {original: site}}: {row: {original: Site}}) => (
        <a className={s.clickableName} onClick={() => setActiveSite(site)}>{site.name}</a>
      )},
      {id: 'startObj', header: 'Start date', sortType: 'datetime', cell: ({row: {original: site}}: {row: {original: Site}}) => (
        site.startObj?.toLocaleDateString()
      )},
      {id: 'endObj', header: 'End date', sortType: 'datetime', cell: ({row: {original: site}}: {row: {original: Site}}) => (
        site.endObj?.toLocaleDateString()
      )},
    ]
  }, [s.clickableName])
  const data = React.useMemo<[Site]>(() => {
    return (allSites || [])
      .filter((site: Site) => site.name.toLowerCase().includes(searchQuery.toLowerCase())) // filter by search query
      .map((site: Site) => ({...site, startObj: new Date(site.start_date), endObj: new Date(site.end_date)})) // map and format dates
      .filter((site: Site & {endObj: Date}) => (site.endObj >= new Date()) === isActiveFilter) // filter by active or expired
  }, [allSites, searchQuery, isActiveFilter])

  const [activeSite, setActiveSite] = useState<Partial<Site>>({});
  const emissionsMetrics: SelectableValue[] = [{label: 'TVOC', value: 'tvoc'}, {label: 'Methane', value: 'ir_c1'}];
  const [defaultEmissionsMetric, setDefaultEmissionsMetric] = useState<'tvoc' | 'ir_c1'>(emissionsMetrics[0].value);

  const createSite = async () => {
    // create a new folder with the pad name, then add "geologists" team with "edit" permissions
    const teamCall = await backendSrv.get('/api/teams/search?name=geologists')
    const geoTeam = teamCall.teams[0]
    const folder = await backendSrv.post('/api/folders', {title: activeSite.name})
    await backendSrv.post(`/api/access-control/folders/${folder.uid}/teams/${geoTeam.id}`, {permission: "Edit"})

    // copy templated dashboard to the pad's folder, replacing db variable and sensor ID variables
    const dashboard = JSON.parse(JSON.stringify({...siteTemplate, id: null, uid: `${folder.uid}-db`, title: activeSite.name}))
    // set default emissions metric based on db variable. this will probably break if we change the dashboard much...
    const variableNdx = dashboard.templating.list.findIndex((item: { name: string; }) => item.name === 'emissionsMetric')
    const selected = dashboard.templating.list[variableNdx].options.find((option: { value: string; }) => option.value === defaultEmissionsMetric)
    dashboard.templating.list[variableNdx].current = selected
    await backendSrv.post('/api/dashboards/db', {
      dashboard,
      folderUid: folder.uid,
    })

    // update activeSite, so if our api call fails it'll update when trying again instead of creating a new folder
    setActiveSite((prevState) => {
      const newState = {...prevState, id: folder.uid}
      // create new site in AG database
      backendSrv.post(`${BASE_URLS.API}/sites/new`, newState).then(() => {
        // redirect to the new dashboard.
        navigate(`/d/${folder.uid}-db`)
      })
      return newState
    })
  }

  const upsertSite = async () => {
    // validate that everything makes sense, or bail:
    const errors = []
    if (activeSite.start_date === undefined || activeSite.end_date === undefined) {
      errors.push('Site must have both a start and end date.')
    }
    if (activeSite.name === undefined || activeSite.name === '') {
      errors.push('Site name cannot be empty.')
    }
    if ([activeSite.max_lat, activeSite.max_lon, activeSite.min_lat, activeSite.min_lon].some(coord => coord === 0)) {
      errors.push('Site bounding box invalid.')
    }
    if (errors.length > 0) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: errors,
      });
      return
    }

    // try to create first, if needed.
    if (activeSite.id === 'new') {
      createSite()
      return
    }

    // otherwise, update site in place. if the site name changed, update the folder and dashboard name
    const ogSite = data.find(site => site.id === activeSite.id)
    if (ogSite?.name !== activeSite.name) {
      await backendSrv.put(`/api/folders/${activeSite.id}`, {title: activeSite.name, overwrite: true})
      const {dashboard} = await backendSrv.get(`/api/dashboards/uid/${activeSite.id}-db`)
      await backendSrv.post('/api/dashboards/db', {
        dashboard: {...dashboard, title: activeSite.name},
        overwrite: true,
        message: 'Name updated',
        folderUid: activeSite.id
      })
    }
    // finally, update the site in the AG db and in the table
    await backendSrv.post(`${BASE_URLS.API}/sites/${activeSite.id}`, activeSite)
    const siteNdx = data.findIndex(site => site.id === activeSite.id)
    data[siteNdx] = activeSite as Site // not sure setting by index is very robust, but resetting allSites does nothing...
    setActiveSite({}) // we're done, close the modal
  }

  return (
    <PluginPage actions={<Button onClick={() => setActiveSite({id: 'new'})}>New site</Button>} pageNav={pageNav}>
      <Modal title={activeSite?.name || 'New site'} isOpen={activeSite?.id !== undefined} onDismiss={() => setActiveSite({})} className={s.modalSmall}>
        <Stack direction={'row'}>
          <div>
            <InlineField label='Name' labelWidth={18} required>
              <Input aria-label='Name' className="width-15" value={activeSite.name}
                onChange={evt => setActiveSite({...activeSite, name: (evt.target as HTMLInputElement).value})}/>
            </InlineField>
            <InlineField label='Start date' labelWidth={18} required>
              <DatePickerWithInput aria-label='Start date' className="width-15" closeOnSelect value={activeSite.start_date}
                onChange={value => setActiveSite({...activeSite, start_date: new Date(value).toISOString()})}/>
            </InlineField>
            <InlineField label='End date' labelWidth={18} required>
              <DatePickerWithInput aria-label='End date' className="width-15" closeOnSelect value={activeSite.end_date}
                onChange={value => setActiveSite({...activeSite, end_date: new Date(value).toISOString()})}/>
            </InlineField>
            <hr/>
            <p>
              <small>Draw a box on the map or manually enter location information.</small>
            </p>
            <InlineField label='Maximum latitude' labelWidth={18} required>
              <Input aria-label='Maximum latitude' className="width-15" value={activeSite.max_lat} type='number'
                onChange={evt => setActiveSite({...activeSite, max_lat: Number((evt.target as HTMLInputElement).value)})}/>
            </InlineField>
            <InlineField label='Maximum longitude' labelWidth={18} required>
              <Input aria-label='Maximum longitude' className="width-15" value={activeSite.max_lon} type='number'
                onChange={evt => setActiveSite({...activeSite, max_lon: Number((evt.target as HTMLInputElement).value)})}/>
            </InlineField>
            <InlineField label='Minimum latitude' labelWidth={18} required>
              <Input aria-label='Minimum latitude' className="width-15" value={activeSite.min_lat} type='number'
                onChange={evt => setActiveSite({...activeSite, min_lat: Number((evt.target as HTMLInputElement).value)})}/>
            </InlineField>
            <InlineField label='Minimum longitude' labelWidth={18} required>
              <Input aria-label='Minimum longitude' className="width-15" value={activeSite.min_lon} type='number'
                onChange={evt => setActiveSite({...activeSite, min_lon: Number((evt.target as HTMLInputElement).value)})}/>
            </InlineField>
            {activeSite?.id === 'new' && ( // set default emissions metric for new sites
              <div>
                <hr/>
                <InlineField label='Emissions metric' labelWidth={18} required>
                  <RadioButtonGroup
                    options={emissionsMetrics}
                    value={defaultEmissionsMetric}
                    onChange={evt => setDefaultEmissionsMetric(evt)}
                  />
                </InlineField>
              </div>
            )}
          </div>
          <MapComponent
            bbox={[activeSite.min_lon, activeSite.min_lat, activeSite.max_lon, activeSite.max_lat]}
            onBoxChange={bbox => setActiveSite(prevState => ({...prevState, min_lon: bbox[0], min_lat: bbox[1], max_lon: bbox[2], max_lat: bbox[3]}))}
          />
        </Stack>
        <Modal.ButtonRow>
          {activeSite?.id !== 'new' && ( // hide for new sites
            <LinkButton href={`/d/${activeSite.id}-db`} target='_blank' icon='external-link-alt' fill='text'>
              Open dashboard
            </LinkButton>
          )}
          <Button variant="secondary" fill="outline" onClick={() => setActiveSite({})}>Cancel</Button>
          <Button onClick={() => upsertSite()}>Save</Button>
        </Modal.ButtonRow>
      </Modal>
      <div>
        <MissingDataSourceAlert/>
        <Stack gap={2} alignItems="center">
          <FilterInput
            placeholder='Search for site by name'
            value={searchQuery}
            escapeRegex={false}
            onChange={(event) => setSearchQuery(event)}
          />
          <RadioButtonGroup
            options={activeOptions}
            value={isActiveFilter}
            onChange={event => setIsActiveFilter(event)}
          />
        </Stack>
        <InteractiveTable columns={cols} data={data} getRowId={(site: Site) => site.id} pageSize={50} className={s.marginTop} />
        {loading && <div><span>Still loading...</span></div>}
      </div>
    </PluginPage>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  marginTop: css`
    margin-top: ${theme.spacing(2)};
  `,
  clickableName: css({
    ':hover': {
      'text-decoration': 'underline',
      color: theme.colors.primary.text
    }
  }),
  modalSmall: css`
    width: fit-content;
  `,
});
