import React, { useEffect, useState } from 'react';
import { getBackendSrv, PluginPage, config } from "@grafana/runtime";
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Button, FilterInput, RadioButtonGroup, InteractiveTable, Stack, Avatar, Checkbox, Modal, InlineField, Input, ConfirmButton, VerticalGroup } from '@grafana/ui';
import { useAsync } from "react-use";
import { OrgTeam, OrgUser } from 'shared/types';
import { capitalizeFirstLetter } from 'shared/utils';
import MissingDataSourceAlert from 'fgs-admin/components/MissingDataSourceAlert';
import { BASE_URLS } from 'shared/constants';

const backendSrv = getBackendSrv();

export function Users() {
  const s = useStyles2(getStyles);

  const pageNav = {
    text: `FGS Admin - ${config.bootData.user.orgName}`,
    subTitle: 'Manage users within this Air Guardian organization.'
  }

  const isFGS = config.bootData.user.orgId === 1

  // manage the users table
  const [searchQuery, setSearchQuery] = useState('');
  const [isActiveFilter, setIsActiveFilter] = useState(true);
  const userActiveOptions = [{
    label: 'Active users',
    value: true,
  }, {
    label: 'Disabled users',
    value: false,
  }];
  // include fgs admins or not in the table. defaults to no for everyone except FGS.
  const [includeFGSadmins, setIncludeFGSadmins] = useState(isFGS);

  const { loading, value } = useAsync(async () => {
    const [usersCall, teamsCall] = await Promise.all([
      backendSrv.get('/api/org/users'),
      backendSrv.get('/api/teams/search?query='),
    ]);
  
    // check to see if we have a geos team already. if not, create one!
    // also do some more org-specific setup that we have to hackily work around grafana...
    if (teamsCall.teams.filter((team: OrgTeam) => team.name === 'geologists').length === 0) {
      // create a team called "geologists" at this org
      const {teamId} = await backendSrv.post('/api/teams', {name: 'geologists'})
      // if we're missing this team, the org was probably just created. enable the plugin too, since grafana is shit
      // and autoEnable doesn't fucking work... https://github.com/grafana/grafana/issues/93243
      await backendSrv.post('/api/plugins/fgs-airguardian-app/settings', {enabled: true, pinned: true})
      // now that our API is enabled, hack RBAC to add some extra permissions for this team. but it can't be that easy, of course,
      // so first we have to create a folder to get grafana to create role/permissions records for this team...
      const folder = await backendSrv.post('/api/folders', {title: 'Default org folder'})
      // then we add permissions to it
      await backendSrv.post(`/api/access-control/folders/${folder.uid}/teams/${teamId}`, {permission: "View"})
      // then we delete the folder
      await backendSrv.delete(`/api/folders/${folder.uid}`)
      // the team role is still around though, so now we can assign RBAC rules to it like hacks... hacks who don't want to
      // pay $50k/year for grafana enterprise just for this one fucking feature.
      await backendSrv.post(`${BASE_URLS.API}/set-team-rbac/${teamId}`)
      // also add the site-info-datasource, since that needs no further credentials
      await backendSrv.post('/api/datasources', {
        name: 'Site info datasource',
        type: 'site-info-datasource',
        access: 'direct',
        editable: false
      })
      // create the shell of the timestream datasource; lower down we'll display an alert that it needs further configuration
      await backendSrv.post('/api/datasources', {
        name: 'Timestream datasource',
        type: 'grafana-timestream-datasource',
        access: 'proxy',
        isDefault: true,
        jsonData: {defaultRegion: 'us-west-2'}
      })
      // update the default alert config to not try to send emails. the receivers aren't created right away though, so this fails...
      // await backendSrv.post('/api/alertmanager/grafana/config/api/v1/alerts', {
      //   template_files: {},
      //   template_file_provenances: {},
      //   alertmanager_config: {
      //     route: {
      //       receiver: 'No notification (only display on charts)',
      //       group_by: ['grafana_folder', 'alertname'],
      //       routes: [
      //         {
      //           receiver: 'No notification (only display on charts)',
      //           object_matchers: [['__grafana_autogenerated__', '=', 'true']],
      //           routes: [
      //             {
      //               receiver: 'No notification (only display on charts)',
      //               group_by: ['grafana_folder', 'alertname'],
      //               object_matchers: [['__grafana_receiver__', '=', 'grafana-default-email']],
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     receivers: [
      //       {
      //         name: 'No notification (only display on charts)',
      //         grafana_managed_receiver_configs: [
      //           {
      //             settings: {
      //               url: 'http://localhost/',
      //             },
      //             secureSettings: {},
      //             type: 'webhook',
      //             name: 'No notification (only display on charts)',
      //             disableResolveMessage: true,
      //             uid: '',
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // })
      // TODO: lab techs team? RBAC hacks?
      // refresh the page so that the team is ready for use. this only happens once per org.
      location.reload()
    }
  
    return [usersCall, teamsCall];
  });

  const orgTeams: OrgTeam[] = value?.length ? value[1]?.teams : [] // all possible teams at the org

  // set up the table cols. the rows are already ready to go in the reports array.
  const cols = React.useMemo(() => {
    return [
      {id: 'avatar', header: '', cell: ({row: {original: user}}: {row: {original: OrgUser}}) => (
        <Avatar src={user.avatarUrl} alt={user.name} width={3} height={3} />
      )},
      {id: 'name', header: 'Name', sortType: 'string', cell: ({row: {original: user}}: {row: {original: OrgUser}}) => (
        <a className={s.clickableName} onClick={() => setActiveUserId(user.userId)}>{user.name}</a>
      )},
      {id: 'email', header: 'Email', sortType: 'string'},
      {id: 'datetimeObj', header: 'Last active', sortType: 'datetime', cell: ({row: {original: user}}: {row: {original: OrgUser}}) => user.lastSeenAtAge}
    ]
  }, [s.clickableName])
  const data = React.useMemo<[Partial<OrgUser>]>(() => {
    const response = value?.length ? value[0] : []
    return response
      .filter((user: OrgUser) => `${user.name} ${user.email} ${user.login}`.toLowerCase().includes(searchQuery.toLowerCase())) // filter by search query
      .filter((user: OrgUser) => user.isDisabled !== isActiveFilter) // filter by disabled status
      .filter((user: OrgUser) => { // filter FGS admins
        return !['@fieldgeoservices.com', '@geodav.tech'].some(domain => user.email.endsWith(domain)) || includeFGSadmins
      })
      .map((user: OrgUser) => ({...user, datetimeObj: new Date(user.lastSeenAt)})) // map and format dates
  }, [value, searchQuery, isActiveFilter, includeFGSadmins])
  //

  // manage the upsert modal
  const [activeUserId, setActiveUserId] = useState(0);
  const [activeUser, setActiveUser] = useState<Partial<OrgUser>>({});
  const [activeUserTeams, setActiveUserTeams] = useState<OrgTeam[]>([]);
  const [activeUserTeams_Unmodified, setActiveUserTeams_Unmodified] = useState<OrgTeam[]>([]);
  const upsertUser = () => {
    // compute which teams we'll need to manage
    const previousTeams = activeUserTeams_Unmodified.map(team => team.id)
    const updatedTeams = activeUserTeams.map(team => team.id)
    const teamsToAdd = updatedTeams.filter(teamId => !previousTeams.includes(teamId)) // present in updatedTeams but not in previousTeams
    const teamsToRemove = previousTeams.filter(teamId => !updatedTeams.includes(teamId)) // present in previousTeams but not in updatedTeams

    if (activeUserId === 9e9) { // new user being created
      // create user and send password setting email, then add teams
      const body = {name: activeUser.name, email: activeUser.email, password: crypto.randomUUID(), orgId: config.bootData.user.orgId}
      backendSrv.post('/api/admin/users', body).then((newUser) => { // new user ONLY HAS THE ID
        // since there's no way to send an invite link while also having a user be immediately active, send password reset link
        backendSrv.post('/api/user/password/send-reset-email', {userOrEmail: activeUser.email}) // i am a hack.

        // add teams
        teamsToAdd.forEach(teamId => backendSrv.post(`/api/teams/${teamId}/members`, {userId: newUser.id}))

        // all FGS users are added as grafana admins, and admins at all existing orgs
        if (isFGS) {
          backendSrv.put(`/api/admin/users/${newUser.id}/permissions`, {isGrafanaAdmin: true})
          backendSrv.patch(`/api/orgs/1/users/${newUser.id}`, {role: "Admin"})
          backendSrv.get('/api/orgs').then(async orgs => {
            // use an old school for loop to make sure each call finishes before we move to the next one, otherwise rate limits.
            for (let index = 0; index < orgs.length; index++) {
              try {
                // skip FGS since we just added org 1 above
                if (orgs[index].id !== 1) {
                  await backendSrv.post(`/api/orgs/${orgs[index].id}/users/`, {
                    role: "Admin",
                    loginOrEmail: activeUser.email
                  })
                }
              } catch {
                console.error(`Failed to add user to org ${orgs[index].id}.`);
              }
            }
          })
        }
      })
    } else {
      // update user properties, both on the server and in the table
      backendSrv.put(`/api/users/${activeUserId}`, activeUser)
      const userNdx = data.findIndex(user => user.userId === activeUserId)
      data[userNdx] = activeUser // i don't think this is right...

      // manage team membership changes
      teamsToAdd.forEach(teamId => backendSrv.post(`/api/teams/${teamId}/members`, {userId: activeUserId})) // add new teams
      teamsToRemove.forEach(teamId => backendSrv.delete(`/api/teams/${teamId}/members/${activeUserId}`)) // remove old teams
    }
    setActiveUserId(0)
  }

  // update the active user when the user id changes
  useEffect(() => {
    const minimalUserSchema = {name: '', email: '', isDisabled: false}
    if (activeUserId === 9e9) { // new user being created
      setActiveUser(minimalUserSchema)
      setActiveUserTeams([])
    } else if (activeUserId) { // id is greater than 0, so we must be modifying an existing user
      setActiveUser(data.find(user => user.userId === activeUserId) || minimalUserSchema)
      backendSrv.get(`/api/users/${activeUserId}/teams`).then((data) => {
        setActiveUserTeams(data);
        setActiveUserTeams_Unmodified(data);
      })
    }
  }, [activeUserId, data]);

  // verb to use when turning a user on or off. also used in the API call.
  const statusVerb = activeUser.isDisabled ? 'Enable' : 'Disable';

  return (
    <PluginPage actions={<Button onClick={() => setActiveUserId(9e9)}>New user</Button>} pageNav={pageNav}>
      <Modal title={activeUser?.name || 'New user'} isOpen={activeUserId > 0} onDismiss={() => setActiveUserId(0)} className={s.modalSmall}>
        <InlineField label='Name' labelWidth={15}>
          <Input aria-label='Name' className="width-15" value={activeUser.name}
            onChange={evt => setActiveUser({...activeUser, name: (evt.target as HTMLInputElement).value})}/>
        </InlineField>
        <InlineField label='Email' labelWidth={15}>
          <Input aria-label='Email' className="width-15" value={activeUser.email}
            onChange={evt => setActiveUser({...activeUser, email: (evt.target as HTMLInputElement).value})}/>
        </InlineField>
        <h4 className={s.paddingTop}>Teams</h4>
        <VerticalGroup>
          {orgTeams.map(team => (
            <Checkbox key={team.id} label={team.name} value={activeUserTeams.map(tm => tm.id).includes(team.id)} 
              onChange={(evt) => (evt.target as HTMLInputElement).checked ? 
                setActiveUserTeams([...activeUserTeams, team]) : setActiveUserTeams(activeUserTeams.filter(tm => tm.id !== team.id))}
            />
          ))}
        </VerticalGroup>
        {isFGS && (
          <div style={{color: 'red', fontWeight: 'bold'}}>
            <hr></hr>
            Users added to the FGS organization are admins across all of Air Guardian.<br></br>
            They will be automatically added as admins to new organizations when they are created.
          </div>
        )}
        <Modal.ButtonRow>
          {activeUserId < 9e9 && ( // hide for new users
            <>
              <ConfirmButton closeOnConfirm confirmText="Delete user?" confirmVariant='destructive' onConfirm={() => {
                backendSrv.delete(`/api/org/users/${activeUserId}`).then(() => {setActiveUserId(0); location.reload()})
              }}>
                <Button variant='destructive' fill='text'>Delete</Button>
              </ConfirmButton>
              <div className={s.btnOverlay}>
                {/* extra div needed due to a z-index bug that renders the delete button over this one. */}
                <ConfirmButton closeOnConfirm confirmText={`${statusVerb} user?`} onConfirm={() => {
                  backendSrv.post(`/api/admin/users/${activeUserId}/${statusVerb.toLowerCase()}`).then(() => {setActiveUserId(0); location.reload()})
                }}>
                  {capitalizeFirstLetter(statusVerb)}
                </ConfirmButton>
              </div>
            </>
          )}
          <Button variant="secondary" fill="outline" onClick={() => setActiveUserId(0)}>Cancel</Button>
          <Button onClick={() => upsertUser()}>Save</Button>
        </Modal.ButtonRow>
      </Modal>
      <div>
        <MissingDataSourceAlert/>
        <Stack gap={2} alignItems="center">
          <FilterInput
            placeholder='Search for user by email or name'
            value={searchQuery}
            escapeRegex={false}
            onChange={(event) => setSearchQuery(event)}
          />
          <Checkbox value={includeFGSadmins} label={"Include FGS?"} onChange={() => setIncludeFGSadmins(!includeFGSadmins)} className={s.textFit}/>
          <RadioButtonGroup
            options={userActiveOptions}
            value={isActiveFilter}
            onChange={event => setIsActiveFilter(event)}
          />
        </Stack>
        <InteractiveTable columns={cols} data={data} getRowId={(user: OrgUser) => user.userId} pageSize={50} className={s.marginTop}/>
        {loading && <div><span>Still loading...</span></div>}
      </div>
    </PluginPage>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  marginTop: css`
    margin-top: ${theme.spacing(2)};
  `,
  paddingTop: css`
    padding-top: ${theme.spacing(3)};
  `,
  textFit: css`
    >span {
      width: max-content;
    }
  `,
  clickableName: css({
    ':hover': {
      'text-decoration': 'underline',
      color: theme.colors.primary.text
    }
  }),
  modalSmall: css`
    width: fit-content;
  `,
  btnOverlay: css`
    z-index: 999;
  `
});
