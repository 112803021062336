import { PluginPage, config, getBackendSrv } from '@grafana/runtime';
import { Button, ConfirmButton, EmptySearchResult, FilterInput, InlineField, Input, Spinner, Stack, useStyles2 } from '@grafana/ui';
import React, { ChangeEvent, useState } from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import MissingDataSourceAlert from 'fgs-admin/components/MissingDataSourceAlert';
import { BASE_URLS } from 'shared/constants';

const backendSrv = getBackendSrv();

interface ActiveSensor {
  id: string;
  lat: number;
  lon: number;
  override?: boolean;
}

export function Sensors() {
  const s = useStyles2(getStyles);

  const pageNav = {
    text: `Sensors - ${config.bootData.user.orgName}`,
    subTitle: 'Manage sensors and their locations (within your currently active org).'
  }

  const [sensorIdQuery, setSensorIdQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeSensor, setActiveSensor] = useState<ActiveSensor | null>(null)

  const fixedSensorId = () => {
    // fix some common possible ID issues
    let query = sensorIdQuery.toUpperCase() // make it uppercase
    if (!query.startsWith('AM-')) { // add AM-
      query = `AM-${query}`
    }
    return query
  }

  // get info about the sensor from terrasls' api
  const querySensor = () => {
    setIsSearching(true)
    setActiveSensor(null)

    // try to fetch the sensor data
    backendSrv.get(`${BASE_URLS.API}/terrasls-proxy/${fixedSensorId()}/gps`).then(sensor => {
      setActiveSensor({...sensor, id: fixedSensorId()})
    }).finally(() => {
      setIsSearching(false)
    })
  }

  const commandSensor = async (command: 'update-gps' | 'clear-gps' | 'reboot') => {
    setIsProcessing(true)

    try { // try any of the sensor commands
      if (command === 'update-gps') {
        const payload = activeSensor ? {lat: activeSensor.lat, lon: activeSensor.lon} : {}
        await backendSrv.put(`${BASE_URLS.API}/terrasls-proxy/${fixedSensorId()}/gps`, payload)
      } else if (command === 'clear-gps') {
        await backendSrv.delete(`${BASE_URLS.API}/terrasls-proxy/${fixedSensorId()}/gps`)
      } else if (command === 'reboot') {
        await backendSrv.get(`${BASE_URLS.API}/terrasls-proxy/${fixedSensorId()}/reboot`)
      }

      // if they succeed, null out the existing active sensor
      setActiveSensor(null)
    } finally { // if they fail for any reason, stop showing the loading spinner
      setIsProcessing(false)
    }
  }

  return (
    <PluginPage pageNav={pageNav}>
      <MissingDataSourceAlert/>
      <Stack gap={2} alignItems="center">
        <FilterInput
          placeholder='Search for a sensor by ID (i.e. AM-6017)'
          value={sensorIdQuery}
          escapeRegex={false}
          onChange={(event) => setSensorIdQuery(event)}
        />
        <Button onClick={querySensor} disabled={isSearching || sensorIdQuery.length === 0}>
          {isSearching ? <Spinner/> : 'Search'}
        </Button>
      </Stack>
      {activeSensor ? (
        <div className={`${s.marginTop} ${s.widthMaxContent}`}>
          <h3>Sensor {activeSensor.id} values</h3>
          <InlineField label='Latitude' labelWidth={28}>
            <Input
              aria-label='Latitude'
              className="width-30"
              value={activeSensor.lat}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                setActiveSensor(prevState => ({...prevState, lat: evt.target.value}))
              }}
            />
          </InlineField>
          <InlineField label='Longitude' labelWidth={28}>
            <Input
              aria-label='Longitude'
              className="width-30"
              value={activeSensor.lon}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                setActiveSensor(prevState => ({...prevState, lon: evt.target.value}))
              }}
            />
          </InlineField>
          <InlineField
            label='Coordinates overridden?'
            labelWidth={28}
            disabled
            tooltip={"Indicates whether the coordinates are being reported by the device's onboard GPS (if any), or if they've been manually overridden."}
          >
            <Input
              aria-label='Coordinates overridden?'
              className="width-30"
              value={activeSensor.override ? 'Yes' : 'No'}
            />
          </InlineField>
          <div className={s.marginTop} style={{marginRight: '4px'}}>
            <Stack gap={2} direction="row" alignItems="flex-end" justifyContent="end">
              {isProcessing ? (
                <div style={{display: 'contents'}}>
                  <Spinner/> Processing...
                </div>
              ) : (
                <>
                  <ConfirmButton closeOnConfirm confirmText='Reboot sensor?' onConfirm={() => commandSensor('reboot')}>
                    Reboot
                  </ConfirmButton>
                  <div className={s.btnOverlay}>
                    {/* extra div needed due to a z-index bug that renders the delete button over this one. */}
                    <ConfirmButton
                      closeOnConfirm
                      confirmText="Remove coordinates?"
                      confirmVariant='destructive'
                      onConfirm={() => commandSensor('clear-gps')}
                    >
                      <Button variant='destructive' fill='text'>Remove coordinates</Button>
                    </ConfirmButton>
                  </div>
                  <Button onClick={() => commandSensor('update-gps')}>
                    Update coordinates
                  </Button>
                </>
              )}
            </Stack>
          </div>
          <div className={s.marginTop} style={{marginRight: '4px'}}>
            <b>Notes</b>
            <ul>
              <li className={s.marginLeft}>
                If a location is already set, it could take 5 minutes before the update occurs.
              </li>
              <li className={s.marginLeft}>
                Rebooting will send a remote command to reset the device.
              </li>
              <li className={s.marginLeft}>
                Sending the command does not guarantee a reboot and there is no acknowledgment response.
              </li>
              <li className={s.marginLeft}>
                The message may not be received if the monitor does not have an adequate network connection.
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className={s.marginTop}>
          <EmptySearchResult>Could not find any sensor matching your query</EmptySearchResult>
        </div>
      )}
    </PluginPage>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  marginTop: css`
    margin-top: ${theme.spacing(2)};
  `,
  widthMaxContent: css`
    width: max-content;
  `,
  btnOverlay: css`
    z-index: 999;
  `,
  marginLeft: css`
    margin-left: ${theme.spacing(2)};
  `,
});
