import { PluginPage, config, getAppEvents, getBackendSrv } from '@grafana/runtime';
import { Button, ConfirmModal, InlineField, Input, Modal, useStyles2 } from '@grafana/ui';
import React, { ChangeEvent, useState } from 'react';
import { css } from '@emotion/css';
import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { useSearchParams } from 'react-router-dom';
import { OrgUser } from 'shared/types';
import { BASE_URLS } from 'shared/constants';

const backendSrv = getBackendSrv();
const appEvents = getAppEvents();

export function Config() {
  const s = useStyles2(getStyles);
  const currentUser = config.bootData.user

  const pageNav = {
    text: `Config - ${currentUser.orgName}`,
    subTitle: "Manage this organization's account. Individual users should be managed via FGS Admin > users."
  }
  
  // the only thing that can be updated for now is the org name.
  const [orgName, setOrgName] = useState(currentUser.orgName);
  
  // but you can still delete the org as a whole...
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const deleteOrgBtn = <Button
    onClick={() => setDeleteModalActive(true)}
    variant='destructive'
    disabled={currentUser.orgId === 1}
  >
    Delete this org
  </Button>
  const deleteCurrentOrgRedirectHack = () => {
    // grafana won't let you delete the currently open org, so first we'll switch context to the root FGS org...
    backendSrv.post('/api/user/using/1').then(() => { // we'll assume it's always ID #1. let's make sure?
      // a page refresh brings us to this same component, but under the FGS root context. we'll add a query arg about
      // which org to delete once the page reloads.
      location.replace(location.href + `?rmOrgId=${currentUser.orgId}&rmOrgName=${currentUser.orgName}`)
    })
  }

  // since we redirect to this component using FGS context to delete orgs, check search params to see if we should remove an org
  const [searchParams, _setSearchParams] = useSearchParams()

  // we don't have a better place to invite new orgs to AirGuardian, so we'll throw that in here too
  const [newOrgName, setNewOrgName] = useState('');
  const [newOrgModalActive, setNewOrgModalActive] = useState(false);
  const addOrgButton = <Button onClick={() => setNewOrgModalActive(true)} disabled={!currentUser.isGrafanaAdmin}>
    Add new org
  </Button>
  const createNewOrg = async () => {
    if (newOrgName.length === 0) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: ['Org name is too short!', 'The organization name must have at least 1 character.'],
      });
      return
    }
    // create new org
    const {orgId} = await backendSrv.post('/api/orgs', {name: newOrgName})
    // get FGS root account users, filter to only admins (excluding self), then add them as admins to this new org
    const fgsUsers: OrgUser[] = await backendSrv.get('/api/orgs/1/users')
    const fgsAdmins = fgsUsers.filter(user => user.role.toLowerCase() === 'admin' && user.userId !== currentUser.id)
    // add each FGS admin as an admin in the new org; don't bother to save the result, but wait for all calls to resolve.
    // use an old school for loop to make sure each call finishes before we move to the next one, otherwise rate limits.
    for (let index = 0; index < fgsAdmins.length; index++) {
      try {
        await backendSrv.post(`/api/orgs/${orgId}/users/`, { role: "Admin", loginOrEmail: fgsAdmins[index].email })
      } catch {
        console.error(`Failed to add user ${fgsAdmins[index].email} to org ${orgId}.`);
      }
    }
    // NOTE: teams are automatically created on the users page if they're not found.
    // finally, switch context to the newly-created org and reload the page
    backendSrv.post(`/api/user/using/${orgId}`).then(() => location.replace(location.origin + BASE_URLS.ADMIN))
  }

  return (
    <PluginPage actions={[addOrgButton, deleteOrgBtn]} pageNav={pageNav}>
      {/* this modal is used on the to-be-deleted org to start the deletion process */}
      <ConfirmModal
        isOpen={deleteModalActive}
        title={`Delete current organization (${currentUser.orgName})`}
        body="All org data and users will be permanently removed. Are you sure you want to delete this organization?"
        confirmText={`Permanently delete ${currentUser.orgName}`}
        onConfirm={deleteCurrentOrgRedirectHack}
        onDismiss={() => setDeleteModalActive(false)}
        confirmationText='delete'
      />
      {/* this modal is used on FGS' config page to finish the deletion of a previous org */}
      <ConfirmModal
        isOpen={searchParams.get('rmOrgId') !== null && searchParams.get('rmOrgName') !== null}
        title={`Finalize removal of ${searchParams.get('rmOrgName')}`}
        body="This is your last chance to prevent deletion."
        confirmText='Confirm deletion'
        onConfirm={() => backendSrv.delete(`/api/orgs/${searchParams.get('rmOrgId')}`).then(() => location.replace(location.origin + '/?orgId=1'))}
        onDismiss={() => location.replace(location.origin + '/?orgId=1')}
      />
      {/* this modal is for adding a new org to air guardian */}
      <Modal title="Add new org" isOpen={newOrgModalActive} onDismiss={() => {setNewOrgModalActive(false)}}>
        <p>
          This will add a new organization to Air Guardian. You&apos;ll be able to invite users in the next step.
        </p>
        <InlineField label='Org name' labelWidth={15}>
          <Input
            aria-label='Org name'
            className="width-20"
            value={newOrgName}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => setNewOrgName(evt.target.value)}
          />
        </InlineField>
        <Modal.ButtonRow>
          <Button variant="secondary" fill="outline" onClick={() => setNewOrgModalActive(false)}>Cancel</Button>
          <Button onClick={createNewOrg}>Save</Button>
        </Modal.ButtonRow>
      </Modal>
      <div>
        <InlineField label='Org name' labelWidth={28}>
          <Input
            aria-label='Org name'
            className="width-30"
            value={orgName}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => setOrgName(evt.target.value)}
          />
        </InlineField>
        <Button
          onClick={() => backendSrv.put('/api/org', {name: orgName}).then(() => location.reload())}
          className={s.marginTop}
        >
          Save
        </Button>
      </div>
    </PluginPage>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  marginTop: css`
    margin-top: ${theme.spacing(2)};
  `,
});
