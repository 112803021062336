import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppRootProps } from '@grafana/data';
import { ROUTES } from '../../constants';
import { Users, Sites, Billing, Config } from '../../pages';

export function App(props: AppRootProps) {
  return (
    <Routes>
      <Route path={ROUTES.users} element={<Users />} />
      <Route path={ROUTES.sites} element={<Sites />} />
      <Route path={ROUTES.billing} element={<Billing />} />
      <Route path={ROUTES.config} element={<Config />} />

      {/* Default page */}
      <Route path="*" element={<Users />} />
    </Routes>
  );
}
